<template>
    <header class="enquiry_form-head"
			:class="{'mod-secondary': secondary }">
        <h1 class="enquiry_form-title">
            <strong class="enquiry_form-title-step"
					v-if="stepNum && !secondary">
                {{ stepNum }}&nbsp;
            </strong>

            {{ title }}
        </h1>
        <div class="enquiry_form-desc">
            <slot></slot>
        </div>
    </header>
</template>

<script>
export default {
    name: "EnquiryHeader",
    props: {
        title: String,
		secondary: Boolean,
    },
	inject: [
		'stepNum',
	],
}
</script>
