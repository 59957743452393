import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


Date.prototype.toISOLocaleString = function() {
	const offset = this.getTimezoneOffset()
	const abs_offset = Math.abs(offset)
	return (new Date(this.getTime() - offset*60*1000).toISOString().substring(0,23) +
		(offset > 0 ? '-' : '+') +
		Math.floor(abs_offset / 60).toFixed(0).padStart(2,'0') + ':' +
		(abs_offset % 60).toString().padStart(2,'0'))
}

window.getOffset = function(el)  {
	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY
	};
}
