<template>
	<Galleria :value="images"
			  :numVisible="5"
			  :showThumbnails="false"
			  :showIndicators="true"
			  :circular="true"
			  :autoPlay="true"
			  :transitionInterval="4000"
	>
		<template #item="slotProps">
			<img :src="slotProps.item" alt="" />
		</template>
	</Galleria>
</template>

<script>
import Galleria from 'primevue/galleria';

export default {
	name: "Gallery",

	components: {
		Galleria,
	},

	props: {
		images: Array,
	},
}
</script>
