export default {

	mounted(el, binding, vnode) {
		const handleOutsideClick = function(e) {
			///console.log(vnode);
			e.stopPropagation()
			// Get the handler method name and the exclude array
			// from the object used in v-closable
			const {handler, exclude} = binding.value
			// This variable indicates if the clicked element is excluded
			let clickedOnExcludedEl = false
			exclude.forEach(refName => {
				// We only run this code if we haven't detected
				// any excluded element yet
				if (!clickedOnExcludedEl) {
					// Get the element using the reference name
					let excludedEl = vnode.ctx.refs[refName]
					// If it exists as a ref, use the exact el
					if (excludedEl) {
						// See if this excluded element
						// is the same element the user just clicked on
						clickedOnExcludedEl = excludedEl.contains(e.target)

					// Else try to find by selector
					} else {
						let excludedEls = document.querySelectorAll(refName);
						excludedEls.forEach(excludedEl => {
							if (excludedEl.contains(e.target)) {
								clickedOnExcludedEl = excludedEl.contains(e.target)
							}
						});
					}

				}
			})
			// We check to see if the clicked element is not
			// the dialog element and not excluded
			if (!el.contains(e.target) && !clickedOnExcludedEl) {
				// If the clicked element is outside the dialog
				// and not the button, then call the outside-click handler
				// from the same component this directive is used in
				handler()
			}
		};

		document.addEventListener('click', handleOutsideClick);
		document.addEventListener('touchstart', handleOutsideClick);
	}
};
