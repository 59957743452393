export default class EnquiryWaypoint {
	constructor(data) {
		this.key = Math.ceil(Math.random() * 1000000000);

		this.address 		= data.address;
		this.latitude 		= data.latitude;
		this.longitude 		= data.longitude;
		this.is_collection  = data.is_collection;
		this.is_destination = data.is_destination;
		this.is_dropoff 	= data.is_dropoff;
		this.is_outbound 	= data.is_outbound;
		this.is_additional 	= !data.is_collection && !data.is_destination && !data.is_dropoff;

		if (data.time) {
			this.date = new Date(data.time);
			this.time = {
				hours: this.date.getHours(),
				minutes: this.date.getMinutes(),
			};
		}


		this.toJson = function() {
			let depart_at = this.date;

			if (depart_at && this.time) {
				depart_at.setHours(this.time.hours);
				depart_at.setMinutes(this.time.minutes);
				depart_at.setSeconds(0);
				depart_at = depart_at.toISOLocaleString();
			}

			return {
				address: this.address,
				latitude: this.latitude,
				longitude: this.longitude,
				is_collection: this.is_collection,
				is_destination: this.is_destination,
				is_dropoff: this.is_dropoff,
				is_outbound: this.is_outbound,
				is_additional: this.is_additional,
				time: depart_at,
			}
		}
	}
}
