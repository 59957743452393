<template>
	<div class="form-field-control mod-has_icon">

		<Icon name="clock" />

		<select v-model="value"
				:required="isRequired"
				@change="onChange($event)"
				@focus="onFocus($event)">
			<option class="placeholder" value="">{{ placeholder }}</option>
			<option v-for="option in options"
					:value="option.value">
				{{ option.label }}
			</option>
		</select>
	</div>
</template>

<script>
import 'v-calendar/dist/style.css';
import Icon from "./Icon.vue";

export default {
	name: "TimeField",

	components: {
		Icon
	},

	props: [
		'modelValue',
		'placeholder',
		'isRequired',
		'defaultToNow',
	],

	emits: [
		'update:modelValue',
	],

	data() {
		return {
			value: this.formatValue(this.modelValue),
			options: this.times(),
		};
	},

	watch: {
		modelValue: function (new_val) {
			this.value = this.formatValue(new_val);
		},
	},

	methods: {
		formatValue(value) {
			if (!value) {
				return '';
			} else {
				return this.formatTime(value.hours, value.minutes);
			}
		},

		times() {
			let times = [];

			for (let time = 0; time < 24; time += 0.25) {
				let hours = Math.floor(time);
				let minutes = 60 * (time - hours);
				let value = this.formatTime(hours, minutes);
				let label = hours +':'+ minutes.toString().padEnd(2, '0');

                if (hours < 12) {
                    label += ' am';
                } else {
                    label += ' pm';
                }

				if (minutes === 0) {
					if (hours === 0) label = 'Midnight';
					if (hours === 12) label = 'Midday';
				}

				times.push({
					label,
					value,
				});
			}

			// Move midnight to end and change to a minute before to save confusion
			let midnight = times.shift();
			times.push({
				label: 'Midnight',
				value: '23:59',
			});

			return times;
		},


		formatTime(hours, minutes) {
			return hours.toString().padStart(2, '0') +':'+ minutes.toString().padEnd(2, '0')
		},


		onChange(event) {
			let parts = event.target.value.split(':');
			let value = {
				hours: parts[0],
				minutes: parts[1],
			};

			this.setModelValue(value.hours, value.minutes)
		},


		onFocus(event) {
           this.setValueAsNow();
        },


		setModelValue(hours, minutes) {
            this.$emit('update:modelValue', {
                hours: hours,
                minutes: minutes,
            });
        },


		setValueAsNow() {
            if (!this.defaultToNow) return;
            if (this.modelValue) return; // Only if nothing selected

            const now = new Date();
            let hours = now.getHours();
            let minutes = now.getMinutes();

            // Calculate the nearest 15-minute interval
            minutes = Math.round(minutes / 15) * 15;
            if (minutes === 60) minutes = 0;

            // Handle the case where the time is midnight
            if (hours === 0 && minutes === 0) {
                hours = 23;
                minutes = 59;
            }

            this.value = this.formatTime(hours, minutes);
            this.setModelValue(hours, minutes);
        }
	}
}
</script>

<style>
</style>
