<template>
    <section class="enquiry_form mod-summary"
			 :class="{
	 			'is-loading': is_loading,
	 			'is-saved': is_saved,
			 }">


		<slot />


		<div class="card">
			<div class="card-body">
				<form class="form mod-labels_left mod-large_labels"
				  @submit.stop.prevent="submit()">

					<div class="form-field enquiry_form-source"
					     :class="{'is-invalid': errors.referrer }">
						<label for="referrer">How did you hear about Coachhop?</label>
						<div class="form-field-control">
							<select id="referrer"
							        v-model="enquiry.referrer">
								<option :value="null">
									Please select
								</option>
								<option
									v-for="option in referrers"
									:value="option">
									{{ option }}
								</option>
							</select>
						</div>
						<FieldErrors :fields="['referrer']" />
					</div>

					<hr class="card-separator">

					<div class="form-field mod-checkbox enquiry_form-opt_in">
						<div class="form-field-control">
							<label>
								<input type="checkbox"
									   value="1"
									   v-model="enquiry.opt_in">
								Would you like to sign up to our newsletter?
							</label>
						</div>
					</div>


					<div class="form-field mod-checkbox enquiry_form-terms"
						 :class="{'is-invalid': errors.terms }">
						<div class="form-field-control">
							<label>
								<input type="checkbox"
									   value="1"
									   v-model="enquiry.terms"
									   required>
								<span>
									I have read and agreed to the
									<a href="/terms-conditions"
									   target="_terms"
									   rel="noreferrer nofollow">
										terms &amp; conditions
									</a>
								</span>
							</label>
						</div>
						<FieldErrors :fields="['terms']" />
					</div>


					<hr class="card-separator">

					<EnquiryActions />
				</form>
			</div>
		</div>
    </section>


	<QuoteLoading :visible="is_saved" />

</template>


<script>
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import LoadingIcon from "./LoadingIcon.vue";
import QuoteLoading from "./QuoteLoading.vue";


export default {
    name: "EnquirySummary",
    components: {
		EnquiryActions,
        EnquiryHeader,
	    QuoteLoading,
        FieldErrors,
		LoadingIcon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
        enquiry: Object,
		referrers: Object,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: true,
		}
	},

	data() {
		return {
			is_saved: false,
		}
	},

    methods: {
        async submit() {
			this.is_loading = true;

			// Save the extra data etc
			// ====================================================================
            let data = {
				opt_in: this.enquiry.opt_in,
				terms: this.enquiry.terms,
				referrer: this.enquiry.referrer,
			};
            let result = await this.$http(this.stepUrl, 'POST', data);
			let response = result.response;

            if (!response || !response.success) {
				this.error = result.error;
				this.errors = result.errors;
				this.is_loading = false;
				return;
            }


			// Submit the job to the API and fetch the redirect URL
			// - either the results page with AP
			// - or the submitted page
			// ====================================================================
			this.is_saved = true;

			let submit_url = response.submit_url;

			result = await this.$http(submit_url, 'POST', data);
			response = result.response;

			if (!response || !response.success) {
				this.error = result.error;
				this.errors = result.errors;
				this.is_loading = false;
				this.is_saved = false;
				return;
			}


			// Redirect to the results
			// ====================================================================
			window.location = response.redirect_url;
        },
    }
}
</script>
