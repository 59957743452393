<template>
	<footer class="form-footer mod-centered">

		<div class="enquiry_form-actions"
			 v-if="hasReachedSummary && !isSummary">
			<a class="button mod-outline"
			   href="/enquiry/summary">
				Cancel
			</a>

			<button class="button"
					type="submit"
					:disabled="is_loading">
				<span v-if="buttonText">{{ buttonText}}</span>
				<span v-else>Save</span>
			</button>
		</div>

		<div class="enquiry_form-actions"
			 v-else>

			<a class="button mod-outline"
			   v-if="prevUrl && !isSummary"
			   :href="prevUrl">
				Previous
			</a>

			<a class="button mod-secondary"
					v-if="skipButtonText"
					:href="nextUrl">
				{{ skipButtonText}}
			</a>

			<button class="button"
			        :class="{'mod-large': isSummary}"
					type="submit"
					:disabled="is_loading">
				<span v-if="buttonText">{{ buttonText}}</span>
				<span v-else-if="stepNum === numSteps">Complete</span>
				<span v-else>Next Step</span>
			</button>
		</div>

	</footer>
</template>

<script>
export default {
    name: "EnquiryActions",
	inject: {
		is_loading: {},
		prevUrl: {},
		nextUrl: {
			default: null,
		},
		stepNum: {},
		numSteps: {},
		buttonText: {},
		hasReachedSummary: {},
		skipButtonText: {
			default: null,
		},
	},
	data: function() {
		return {
			isSummary: this.stepNum > this.numSteps,
		}
	}
}
</script>
