<template>
    <div class="flash mod-danger" v-if="error">
        <div class="wrap" v-html="error"></div>
    </div>
    <div class="flash mod-warning" v-if="warning">
        <div class="wrap" v-html="warning"></div>
    </div>
    <div class="flash mod-warning" v-if="success">
        <div class="wrap" v-html="success"></div>
    </div>
    <div class="flash mod-warning" v-if="info">
        <div class="wrap" v-html="info"></div>
    </div>
</template>

<script>
export default {
    name: "Flash",
    inject: [
        'error',
        'warning',
        'success',
        'info',
    ],
}
</script>
