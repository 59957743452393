<template>
    <section class="enquiry_form mod-journey-extras"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress />


        <EnquiryHeader title="Need any additional pickups?">
            <p>
	            Please let us know about any additional pick-ups and drop-offs required.
            </p>
        </EnquiryHeader>


        <form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()">

			<div class="form-field mod-toggle">
				<label>Additional Pick-ups</label>
				<ToggleField v-model="pickups_required"
							 on-label="Yes"
							 off-label="No"
				/>
			</div>


			<div v-if="pickups_required"
				 v-for="(waypoint, i) in waypoints"
				:key="waypoint.key">
				<div v-if="waypoint.is_outbound"
					 class="form-field mod-location enquiry_form-journey-collection_address"
					 :class="{'is-invalid': errors.waypoints && (errors.waypoints[i].address || errors.waypoints[i].lat || errors.waypoints[i].lng) }">
					<label>
						<button type="button"
								class="button mod-close"
								@click="removeWaypoint(waypoint)"
								title="Remove this Pickup">
						</button>
						Pick-up {{ waypointNumber(waypoint) }}
					</label>
					<AddressField v-model="waypoints[i]"></AddressField>
					<FieldErrors :fields="['waypoints.'+i+'.address', 'waypoints.'+i+'.lat', 'waypoints.'+i+'.lng']"
								 label="pickup"/>
				</div>
			</div>

			<div v-if="pickups_required"
				 class="enquiry_form-field-actions">
				<button type="button"
						class="button mod-add mod-blue"
						@click="addOutboundWaypoint()">
					+ Add
				</button>
			</div>


			<div v-if="is_return"
				 class="form-field mod-toggle">
				<label>Additional Drop offs</label>
				<ToggleField v-model="dropoffs_required"
							 on-label="Yes"
							 off-label="No"
				/>
			</div>


			<div v-if="dropoffs_required"
				 v-for="(waypoint, i) in waypoints"
				 :key="waypoint.key">
				<div v-if="!waypoint.is_outbound"
					 class="form-field mod-location enquiry_form-journey-collection_address"
					 :class="{'is-invalid': errors.waypoints && (errors.waypoints[i].address || errors.waypoints[i].lat || errors.waypoints[i].lng) }">
					<label>
						<button type="button"
								class="button mod-close"
								@click="removeWaypoint(waypoint)"
								title="Remove this Drop off">
						</button>
						Drop off {{ waypointNumber(waypoint) }}
					</label>
					<AddressField v-model="waypoints[i]">
					</AddressField>
					<FieldErrors :fields="['waypoints.'+i+'.address', 'waypoints.'+i+'.lat', 'waypoints.'+i+'.lng']"
								 label="dropoff"/>
				</div>
			</div>

			<div v-if="dropoffs_required"
				 class="enquiry_form-field-actions">
				<button type="button"
						class="button mod-add mod-blue"
						@click="addInboundWaypoint()">
					+ Add
				</button>
			</div>



			<EnquiryActions />
        </form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import AddressField from "./AddressField.vue";
import ToggleField from "./ToggleField.vue";
import EnquiryWaypoint from "../models/enquiry_waypoint";


export default {
    name: "EnquiryJourneyExtras",
    components: {
		EnquiryActions,
		AddressField,
		ToggleField,
        EnquiryProgress,
        EnquiryHeader,
        FieldErrors,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
        enquiry: Object,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},

    data() {
		let waypoints = this.enquiry?.waypoints ?? [];

		let is_return = waypoints.some(waypoint => waypoint.is_dropoff);

		waypoints = waypoints.map((waypoint) => {
			return new EnquiryWaypoint(waypoint);
		});
		waypoints = waypoints.filter((waypoint) => {
			return waypoint.is_additional;
		});

        return {
			waypoints: waypoints,
            pickups_required: waypoints.some(waypoint => waypoint.is_outbound),
            dropoffs_required: waypoints.some(waypoint => !waypoint.is_outbound),
			is_return: is_return,
        }
    },

	watch: {
		pickups_required(new_val) {
			if (!new_val) return;

			let has_pickup = this.waypoints.some(waypoint => waypoint.is_outbound);

			if (has_pickup) return;

			this.addOutboundWaypoint();
		},

		dropoffs_required(new_val) {
			if (!new_val) return;

			let has_dropoff = this.waypoints.some(waypoint => !waypoint.is_outbound);
			if (has_dropoff) return;

			this.addInboundWaypoint();
		},
	},

    methods: {
		addOutboundWaypoint() { // insert as last outbound
			let i = this.waypoints.findLastIndex(waypoint => waypoint.is_outbound);
			if (i === -1) i = 0;
			i++;
			this.addWaypoint(i, {is_outbound: true})
		},

		addInboundWaypoint() { // insert at end
			let i = this.waypoints.length;
			this.addWaypoint(i, {is_outbound: false})
		},

		addWaypoint(i, attrs) {
			attrs.is_additional = true;
			let waypoint = new EnquiryWaypoint(attrs);
			this.waypoints.splice(i, 0, waypoint)
		},

		removeWaypoint(waypoint) {
			let index = this.waypoints.indexOf(waypoint);
			this.waypoints.splice(index, 1);
		},

		// Get the human readable number for the type of waypoint
		waypointNumber(waypoint) {
			let index = this.waypoints.indexOf(waypoint);

			if (waypoint.is_outbound) {
				return index + 1;
			} else {
				let pickups = this.waypoints.filter(waypoint => waypoint.is_outbound);
				return index + 1 - pickups.length;
			}
		},

        async submit() {

			if (!this.pickups_required) {
				// Only include dropoffs if extra pickups was deselected
				this.waypoints = this.waypoints.filter(waypoint => !waypoint.is_outbound);
			}
			if (!this.dropoffs_required) {
				// Only include pickups if extra dropoffs was deselected
				this.waypoints = this.waypoints.filter(waypoint => waypoint.is_outbound);
			}

            let data = {
				waypoints: this.waypoints,
			};

			this.is_loading = true;

            const { response, error, errors } = (
                await this.$http(this.stepUrl, 'POST', data)
            );

            this.error = error;
            this.errors = errors;

            if (response && response.success) {
				if (this.hasReachedSummary) {
					window.location = '/enquiry/summary';
				} else {
					window.location = this.nextUrl;
				}
            }

			this.is_loading = false;
        },
    }
}
</script>
