<template>
    <section class="enquiry_form mod-journey"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress v-if="!simple" />


        <EnquiryHeader title="Your Journey" v-if="!simple">
        </EnquiryHeader>


        <form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()">

			<div class="enquiry_form-journey-row">
				<div class="form-field mod-toggle enquiry_form-journey-is_return">
					<label>Journey Type</label>
					<ToggleField v-model="is_return"
								 on-label="Return"
								 off-label="Single"
					/>
				</div>


				<div class="form-field mod-number mod-narrow enquiry_form-journey-passengers"
					 :class="{'is-invalid': errors.passengers }">
					<label for="passengers">Passengers</label>
					<div class="form-field-control mod-has_icon">
						<Icon name="user" />
						<input id="passengers"
							   :placeholder="simple ? 'Passengers' : ''"
							   type="number"
							   min="1"
							   required
							   v-model="enquiry.passengers">
					</div>
					<FieldErrors :fields="['passengers']" />
				</div>
			</div>


			<hr class="separator">


			<div class="enquiry_form-journey-row">
				<div class="form-field mod-location enquiry_form-journey-collection_address"
					 :class="{'is-invalid': 
					 	errors['collection.address'] || errors['collection.latitude'] || errors['collection.longitude']
					}">
					<label>Travelling from</label>

					<AddressField v-model="collection"
								  :placeholder="simple ? 'Travelling from' : null"
								  />
					<FieldErrors :fields="['collection.address', 'collection.latitude', 'collection.longitude']"
								 label="collection"/>
				</div>


				<div class="form-field mod-date enquiry_form-journey-collection_date"
					 :class="{'is-invalid': errors['collection.time'] }">
					<label>Leaving On</label>
					<DateField
						v-model="collection.date"
						:min-date="new Date()"
						:is-invalid="errors['collection.time'] !== undefined"
						:is-required="true"
						:placeholder="simple ? 'Leaving on' : null" />
					<FieldErrors :fields="['collection.time']"
								 label="date"/>
				</div>

				<div class="form-field mod-time enquiry_form-journey-collection_time"
					 :class="{'is-invalid': errors['collection.time'] }">
					<label>Leaving At</label>
					<TimeField
						v-model="collection.time"
						:is-required="true"
						:placeholder="'Time'"
						:default-to-now="true"/>
					<FieldErrors :fields="['collection.time']"
								 label="time"/>
				</div>
			</div>


			<hr class="separator">


			<div class="enquiry_form-journey-row" >
				<div class="form-field mod-location enquiry_form-journey-destination_address"
					 :class="{'is-invalid': errors['destination.address'] || errors['destination.latitude'] || errors['destination.longitude']}">
					<label>Travelling to</label>
					<AddressField v-model="destination"
								  :placeholder="simple ? 'Travelling to' : null"
								  />
					<FieldErrors :fields="['destination.address', 'destination.latitude', 'destination.longitude']"
								 label="destination"/>
				</div>

				<div class="form-field mod-date enquiry_form-journey-destination_date"
					 :class="{
						'is-invalid': errors['destination.time'],
						'slide-down': is_return,
						'slide-up': !is_return,
				     }">
					<label>Return on</label>
					<DateField v-model="destination.date"
							   :min-date="collection.date || new Date()"
							   :is-invalid="errors['destination.time'] !== undefined"
							   :is-required="is_return"
							   :placeholder="simple ? 'Return on' : null" />
					<FieldErrors :fields="['destination.time']"
								 label="return date"/>
				</div>

				<div class="form-field mod-time enquiry_form-journey-destination_time"
					 :class="{
						'is-invalid': errors['destination.time'],
						'slide-down': is_return,
						'slide-up': !is_return,
					 }">
					<label>Return At</label>
					<TimeField
						v-model="destination.time"
						:is-required="is_return"
						:placeholder="'Time'"
						:default-to-now="true"/>
					<FieldErrors :fields="['destination.time']"
								 label="return time"/>
				</div>
			</div>


			<EnquiryActions />
        </form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import AddressField from "./AddressField.vue";
import ToggleField from "./ToggleField.vue";
import DateField from "./DateField.vue";
import TimeField from "./TimeField.vue";
import Icon from "./Icon.vue";
import EnquiryWaypoint from "../models/enquiry_waypoint";

export default {
    name: "EnquiryJourney",
    components: {
		EnquiryActions,
		AddressField,
		ToggleField,
		DateField,
		TimeField,
        EnquiryProgress,
        EnquiryHeader,
        FieldErrors,
		Icon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
        enquiry: Object,
        simple: Boolean,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},

    data() {

		let waypoints = this.enquiry.waypoints ?? [];
		let new_journey = waypoints.length === 0;

		if (new_journey) {
			waypoints.push(new EnquiryWaypoint({is_collection: true}));
			waypoints.push(new EnquiryWaypoint({is_destination: true}));

		} else {
			waypoints = waypoints.map((waypoint) => {
				return new EnquiryWaypoint(waypoint);
			});
		}

		let collection = waypoints.find((waypoint => {
			return waypoint.is_collection;
		}))

		let destination = waypoints.find((waypoint => {
			return waypoint.is_destination;
		}));

		let is_return = (new_journey || destination.time);

        return {
            is_return: is_return,
			collection: collection,
			destination: destination,
        }
    },

	watch: {
		is_return: function (new_is_return) {
			if (!new_is_return) {
				this.destination.date = null;
				this.destination.time = null;
			}
		},
	},

    methods: {
        async submit() {
			this.is_loading = true;

            let data = {
				passengers:  this.enquiry.passengers,
				collection:  this.collection.toJson(),
				destination: this.destination.toJson(),
			};

			if (this.is_return) {
				data.is_return = true;
			}

            const { response, error, errors } = (
                await this.$http(this.stepUrl, 'POST', data)
            );

            this.error = error;
            this.errors = errors;

            if (response && response.success) {
				// Unlike the other steps, always continue to the nextUrl
	            // rather than the summary, so that we can take the user
	            // to the additional waypoints step after reaching summary
				window.location = this.nextUrl;
            }

			this.is_loading = false;
        },
    },


	computed: {
		shouldHighlightCollection: function() {
			return this.collection.address && !this.isCollectionGeoLocated;
		},

		isCollectionGeoLocated: function() {
			return (
				this.collection.longitude &&
				this.collection.latitude
			);
		},
	}
}
</script>
