<template>
    <section class="enquiry_form mod-contact"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress />


        <EnquiryHeader title="Your contact information">
            <p>
				Let us know your name and how we can get into contact with you.
            </p>
        </EnquiryHeader>


        <form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()">

			<div class="form-field"
				 :class="{'is-invalid': errors.first_name }">
				<label for="first_name">First name</label>
				<div class="form-field-control">
					<input id="first_name"
						   type="text"
						   required
						   v-model="enquiry.first_name">
				</div>
				<FieldErrors :fields="['first_name']" />
			</div>


			<div class="form-field"
				 :class="{'is-invalid': errors.last_name }">
				<label for="last_name">Last name</label>
				<div class="form-field-control">
					<input id="last_name"
						   type="text"
						   required
						   v-model="enquiry.last_name">
				</div>
				<FieldErrors :fields="['last_name']" />
			</div>


			<div class="form-field"
				 :class="{'is-invalid': errors.phone }">
				<label for="phone">Phone number</label>
				<div class="form-field-control">
					<input id="phone"
						   type="text"
						   required
						   v-model="enquiry.phone">
				</div>
				<FieldErrors :fields="['phone']" />
			</div>


			<div class="form-field"
				 :class="{'is-invalid': errors.email }">
				<label for="email">Email address</label>
				<div class="form-field-control">
					<input id="email"
						   type="email"
						   required
						   v-model="enquiry.email">
				</div>
				<FieldErrors :fields="['email']" />
			</div>


			<EnquiryActions />
        </form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import Icon from "./Icon.vue";


export default {
    name: "EnquiryContact",
    components: {
		EnquiryActions,
        EnquiryProgress,
        EnquiryHeader,
        FieldErrors,
        Icon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
        'user_id',
    ],

    props: {
        enquiry: Object,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},


    methods: {

		async submit() {
			this.is_loading = true;

			let data = {
				first_name: this.enquiry.first_name,
				last_name:  this.enquiry.last_name,
				phone:  	this.enquiry.phone,
				email:  	this.enquiry.email,
			};

			const { response, error, errors } = (
				await this.$http(this.stepUrl, 'POST', data)
			);

			this.error = error;
			this.errors = errors;

			if (response && response.success) {
				if (this.hasReachedSummary) {
					window.location = '/enquiry/summary';
				} else {
					window.location = this.nextUrl;
				}
			}

			this.is_loading = false;
		},
    }
}
</script>
