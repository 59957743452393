<template>
	<div class="form-field-control mod-has_icon"
		:class="{'is-open': isOpen }"
		 v-closable="{
			exclude: [
				'dateField',
			],
			handler: close
		}">

		<Icon name="date" />

		<input type="text"
			   ref="textField"
			   :required="isRequired"
			   readonly="readonly"
			   :value="dateFormatted"
			   :placeholder="placeholder ?? 'Choose a date'"
			   @click="open">

		<Teleport to="body">
			<div class="date_field"
				 :class="{'is-invalid': isInvalid}"
				 ref="dateField"
				 v-show="isOpen">

				<DatePicker v-model="value"
							@dayclick="emitValue"
							:min-date="minDate"
							:is-required="true"
							mode="date"
							ref="datePicker" />

				<button type="button"
						class="button mod-close"
						@click="close"
						title="Close"
						v-show="!value"
				></button>

				<button type="button"
						class="button mod-confirm"
						@click="close"
						title="Confirm"
						v-show="value"
				></button>
			</div>
		</Teleport>
	</div>
</template>

<script>
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import Icon from "./Icon.vue";

export default {
	name: "DateField",

	components: {
		Icon,
		DatePicker,
	},

	props: [
		'modelValue',
		'placeholder',
		'minDate',
		'isInvalid',
		'isRequired',
	],

	emits: [
		'update:modelValue',
	],

	data() {
		return {
			isOpen: false,
			value: this.modelValue,
		};
	},

	watch: {
		modelValue: function (new_val) {
			this.value = new_val;
		},
		minDate(curr, prev) {
			if (curr) {
				this.$refs.datePicker.move(this.initialPage);
			}
		}
	},

	computed: {
		dateFormatted() {
			if (!this.value) return '';

			let options =  {
				day: 'numeric',
				weekday: 'short',
				year: 'numeric',
				month: 'short',
			}
			return this.value.toLocaleDateString('en-GB', options);
		},

		initialPage() {
			let refDate = !this.minDate ? new Date() : this.minDate;

			if (this.value) {
				refDate = this.value;
			}

			return {
				month: refDate.getMonth() + 1,
				year: refDate.getFullYear()
			}
		}
	},

	methods: {
		open() {
			this.isOpen = true;

			const offset = getOffset(this.$el);
			const top = (this.$refs.textField.scrollHeight + offset.top);
			this.$refs.dateField.style.top = top+'px';
			this.$refs.dateField.style.left = offset.left+'px';
			this.$refs.dateField.style.width = this.$el.offsetWidth+'px';

			if (this.minDate && !this.value) {
				this.value = this.minDate;
				this.$emit('update:modelValue', this.value);
			}


			if (window.visualViewport.height < 800) {
				this.$refs.textField.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		},

		close() {
			this.isOpen = false;
		},

		emitValue() {
			this.$emit('update:modelValue', this.value);
			this.close();
		},
	},
}
</script>
