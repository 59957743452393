<template>

	<form class="payment_method form mod-labels_left mod-large_labels"
		  @submit.stop.prevent="submit()"
		 :class="{'is-loading': is_loading}">

		<div class="form-field mod-payment"
			 :class="{'is-invalid': errors?.payment_method }">
			<div ref="paymentElements"></div>
			<FieldErrors :fields="['payment_method']" />
		</div>

		<button type="submit"
				class="button mod-wide">
			Save
		</button>

		<LoadingIcon v-show="is_loading" />
	</form>
</template>


<script>
import FieldErrors from "./FieldErrors.vue";
import LoadingIcon from "./LoadingIcon.vue";
import {loadStripe} from "@stripe/stripe-js/pure";

export default {
    name: 'PaymentMethod',
    components: {
		FieldErrors,
		LoadingIcon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
		setup_intent_secret: String,
	},

	data() {
		return {
			stripe: null,
			elements: null,
			payment_el: null,
			payment_method: null,
		}
	},


	async mounted() {
		this.stripe = await loadStripe(STRIPE_PUB_KEY, {
			betas: ['elements_enable_deferred_intent_beta_1'],
		});

		this.elements = this.stripe.elements({
			'clientSecret': this.setup_intent_secret,
			appearance: STRIPE_APPEARANCE,
			fonts: STRIPE_FONTS,
		});
		this.payment_el = this.elements.create('payment', {
			layout: 'tabs',
		});

		this.payment_el.mount(this.$refs.paymentElements);
	},


    methods: {
		async submit() {
			this.is_loading = true;
			this.errors = null;

			this.payment_method = await this.createPaymentMethod();

			if (!this.payment_method) {
				this.is_loading = false;
				this.errors = {'payment_method': ['Please enter a payment method']};
				this.payment_el.focus();
				return;
			}

			await this.savePaymentMethod()

			this.is_loading = false;
		},


		async createPaymentMethod() {
			let elements = this.elements;

			return await this.stripe.createPaymentMethod({
					elements,
					params: {
						billing_details: this.billing_details,
						allow_redisplay: 'always',
					}
				})
				.then(function(result) {
					if (result.error) {
						this.error = result.error;
						return null;
					} else {
						return result.paymentMethod
					}
				});
		},


		async savePaymentMethod() {
			const url = '/dashboard/payment-methods/add';
			const data = {
				payment_method_id: this.payment_method.id,
			};
			const { response, error, errors } = (
				await this.$http(url, 'POST', data)
			);

			this.error = error;
			this.errors = errors;

			if (response.success) {
				window.location.href = '/dashboard/payment-methods';
			}
		},
    },
}
</script>
