<template>
    <section class="enquiry_form mod-account"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress />


        <EnquiryHeader title="Would you like to create an account?">
            <p>
				Creating an account with give you access to your own profile where you can view your enquiries and monitor your bookings.
				We will use the email address you provided above for your login name.
            </p>
			<p>
				Already have an account?
				<a href="/login" @click.prevent="authRedirect">Login now</a>
			</p>
        </EnquiryHeader>


		<form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()">

			<div class="form-field"
				 :class="{'is-invalid': errors.email }">
				<label for="email">Email</label>
				<div class="form-field-control">
					<input id="email"
						   type="email"
						   required
						   v-model="enquiry.email">
				</div>
				<FieldErrors :fields="['email']" />
			</div>

			<div class="form-field"
				 :class="{'is-invalid': errors.password }">
				<label for="password">Password</label>
				<div class="form-field-control">
					<input id="password"
						   type="password"
						   required
						   v-model="enquiry.password">
				</div>
				<FieldErrors :fields="['password']" />
			</div>

			<div class="form-field"
				 :class="{'is-invalid': errors.password_confirmation }">
				<label for="email">Confirm password</label>
				<div class="form-field-control">
					<input id="password_confirmation"
						   type="password"
						   required
						   v-model="enquiry.password_confirmation">
				</div>
			</div>

			<div class="enquiry_form-social_login login-social">
				<a class="button mod-full mod-large mod-facebook"
				   @click.prevent="authRedirect"
				   :href="facebookAuthUrl">
					<Icon name="facebook" />
					Sign in with Facebook
				</a>

				<a class="button mod-full mod-large mod-google"
				   @click.prevent="authRedirect"
				   :href="googleAuthUrl">
					<Icon name="google" />
					Sign in with Google
				</a>
			</div>


			<EnquiryActions />
		</form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import Icon from "./Icon.vue";


export default {
    name: "EnquiryContact",
    components: {
		EnquiryActions,
        EnquiryProgress,
        EnquiryHeader,
        FieldErrors,
        Icon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
        'user_id',
    ],

    props: {
        enquiry: Object,
        buttonText: String,
		skipButtonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
        serverErrors: Object,
		facebookAuthUrl: String,
		googleAuthUrl: String,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			nextUrl: this.nextUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			secondaryButtonText: this.secondaryButtonText,
			skipButtonText: this.skipButtonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},


	mounted: function() {
		this.errors = this.serverErrors;
	},


    methods: {
		async authRedirect(e) {
			this.is_loading = true;

			// Set the return uri
			let url = window.location.href;
			url = encodeURIComponent(url);
			let redirect = e.target.href;

			// Redirect to auth if no password entered
			if (!this.enquiry.password) {
				window.location = redirect + '?continue=' + url;

			// Save password before redirecting
			} else {
				let data = {};
				data.email 					= this.enquiry.email;
				data.password 				= this.enquiry.password;
				data.password_confirmation 	= this.enquiry.password_confirmation;

				let response = await this.saveEnquiry(data);

				if (response && response.success) {
					window.location = redirect+'?continue='+url;
				}
			}

			this.is_loading = false;
		},



		async submit() {
			this.is_loading = true;

			let data = {
				email:				   this.enquiry.email,
				password:  			   this.enquiry.password,
				password_confirmation: this.enquiry.password_confirmation,
			};

			let response = await this.saveEnquiry(data);

			if (response && response.success) {
				if (this.hasReachedSummary) {
					window.location = '/enquiry/summary';
				} else {
					window.location = this.nextUrl;
				}
			}

			this.is_loading = false;
		},


		async saveEnquiry(data) {
			const { response, error, errors } = (
				await this.$http(this.stepUrl, 'POST', data)
			);

			this.error = error;
			this.errors = errors;

			return response;
		},
    }
}
</script>
