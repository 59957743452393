
<template>
	<div class="payment">
		<div ref="paymentElements"></div>
	</div>
</template>


<script>
import {loadStripe} from "@stripe/stripe-js/pure";

export default {
	name: 'GpayTest',

	props: {
		payment_intent_secret: String,
	},

	data() {
		return {
			stripe: null,
			elements: null,
		}
	},


	async mounted() {
		this.stripe = await loadStripe(STRIPE_PUB_KEY);

		this.elements = this.stripe.elements({
			'clientSecret': this.payment_intent_secret,
			appearance: STRIPE_APPEARANCE,
			fonts: STRIPE_FONTS,
		});
		this.payment_el = this.elements.create('payment', {
			layout: 'tabs',
		});

		this.payment_el.mount(this.$refs.paymentElements);
	},

}
</script>
