<template>
	<div class="quote_loading"
         :class="{
            'is-shown': visible,
		 }">
		<LoadingIcon />
		<h2>
			We are searching our network to find you the best deals!
		</h2>
		<p>
			We will be right with you!
		</p>
	</div>
</template>

<script>
import LoadingIcon from "./LoadingIcon.vue";

export default {
	name: "QuoteLoading",
	components: {
		LoadingIcon
	},
	props: {
		visible: Boolean,
	}
}

</script>