<template>
    <div class="form-field-errors" v-if="errors && hasErrors">
        <div v-for="field in fields">
            <span v-for="error in errors[field]">
				<span v-if="typeof error === 'string'">
					{{ error.replace(field, label ?? field).replace(' field ', ' ') }}
				</span>
			</span>
        </div>
    </div>
</template>

<script>
import { some } from 'lodash';

export default {
    name: "FieldErrors",
    inject: [
        'errors',
    ],
    props: {
        fields: Array,
        label: String,
    },

    computed: {
        hasErrors: function() {
            return some(this.fields, (field) => {
                return this.errors.hasOwnProperty(field);
            });
        }
    }
}
</script>
