<template>
<i class="icon" :class="'mod-' + name" v-html="svgContent"></i>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: String,
    },
    data() {
        return {
            svgContent: '',
        };
    },
    watch: {
        name: 'loadSvg',
    },
    mounted() {
        this.loadSvg();
    },
    methods: {
        async loadSvg() {
        try {
            const response = await fetch(`/img/icons/${this.name}.svg`);
            if (response.ok) {
            this.svgContent = await response.text();
            } else {
            console.error('Error loading SVG:', response.statusText);
            }
        } catch (error) {
            console.error('Error loading SVG:', error);
        }
        },
    },
}
</script>
  