<template>
    <div class="article_thumbs-filter">
        <ul class="article_thumbs-filter-tags"
            v-show="showTagFilter">
           <li @click="filter.tag = null"
               :class="{'is-on': filter.tag === null}">All</li>

            <li v-for="(tag_name, tag_slug) in tagsForFilter"
                @click="filter.tag = tag_slug"
                :class="{'is-on': filter.tag === tag_slug}">
                {{ tag_name }}
            </li>
        </ul>

        <div class="article_thumbs-filter-text form-field mod-search"
             v-show="showTagFilter">
			<div class="form-field-control mod-has_icon">
				<Icon name="search" />
				<input type="search"
					   v-model="filter.text"
					   placeholder="Search">
			</div>
        </div>
    </div>

    <slot name="initial" :isVisible="isVisible" :hasResults="hasResults" />
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: 'ArticleThumbs',
    props: {
        showTagFilter: Boolean,
        showTextFilter: Boolean,
        tagsForFilter: Object,
		articleMap: Array,
    },

	components: {
		Icon
	},

    data: function(){
        return {
            filter: {
                tag: null,
                text: '',
            }
        }
    },

    methods: {
        isVisible(id) {
			return this.results.find(article => {
				return article.id === id;
			});
        },

        hasResults() {
			return this.results.length > 0;
        }
    },

	computed: {
		results() {
			if (this.filter.text === null && this.filter.tag === null) {
				return this.articleMap;
			}

			let results = this.articleMap.filter(article => {
				let text_match = true;
				if (this.filter.text.length > 0) {
					text_match = article.title.toLowerCase().indexOf(
						this.filter.text.toLowerCase()
					) > -1;
				}

				let tags_match = true;
				if (this.filter.tag !== null) {
					tags_match = article.tags.includes(this.filter.tag);
				}

				return text_match && tags_match;
			});

			return results;
		}
	}
}
</script>
