<template>
	<div class="form-field-control">
		<label :class="{'is-on': !modelValue}">
			<input id="journey_type"
				   type="radio"
				   :value="false"
				   :checked="!modelValue"
				   @change="$emit('update:modelValue', false)">
			{{ offLabel }}
		</label>
		<label :class="{'is-on': modelValue}">
			<input type="radio"
				   :value="true"
				   :checked="modelValue"
				   @change="$emit('update:modelValue', true)">
			{{ onLabel }}
		</label>
	</div>
</template>

<script>
export default {
	name: "ToggleField",

	props: [
		'modelValue',
		'offLabel',
		'onLabel',
	],

	emits: [
		'update:modelValue',
	],
}
</script>

<style scoped>
</style>
