<template>
    <div class="enquiry_form-progress"
		 v-if="numSteps && stepNum">
        <h6 class="enquiry_form-progress-title">
            <span v-if="stepNum === numSteps">
                The final step!
            </span>
            <span v-else>
                {{ stepNum }} / {{ numSteps }}
            </span>
        </h6>

        <div class="enquiry_form-progress-bar">
            <div class="enquiry_form-progress-bar-fill"
                 :style="{
                    width: percentage+'%'
                }">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnquiryProgress",
	inject: [
		'stepNum',
		'numSteps',
	],
    computed: {
        percentage: function() {
            return (this.stepNum / this.numSteps) * 100;
        }
    }
}
</script>
