<template>
    <section class="enquiry_form mod-job_info"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress />


        <EnquiryHeader title="Tell us your reason for travel">
            <p>
				Telling us your reason for travel will help us identify the most suitable vehicles to meet your needs. Click on one of the below options.
            </p>
        </EnquiryHeader>


        <form class="form" @submit.stop.prevent="submit()">

			<div class="form-field">
				<ul class="enquiry_form-event_types">
					<li v-for="event_type in eventTypes"
						key="id"
						class="enquiry_form-event_type"
						:class="{'is-on': selected_event_id === event_type.id}">
						<label>
							<Icon :name="'event-'+event_type.slug" />
							{{ event_type.label }}
							<input type="radio"
								   v-model="selected_event_id"
								   :value="event_type.id"
								   @change="submit"
							>
						</label>
					</li>
				</ul>

				<FieldErrors :fields="['rs_event_type_id']"
							 label="type of event" />
			</div>

			<EnquiryActions />
        </form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import Icon from "./Icon.vue";


export default {
    name: "EnquiryJourney",
    components: {
		EnquiryProgress,
		EnquiryHeader,
		EnquiryActions,
		FieldErrors,
		Icon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
        enquiry: Object,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
		eventTypes: Array,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},

    data() {
        return {
			selected_event_id: this.enquiry.rs_event_type_id,
        }
    },

    methods: {
        async submit() {
			this.is_loading = true;

            let data = {
				rs_event_type_id: this.selected_event_id,
			};


            const { response, error, errors } = (
                await this.$http(this.stepUrl, 'POST', data)
            );

            this.error = error;
            this.errors = errors;

            if (response && response.success) {
				if (this.hasReachedSummary) {
					window.location = '/enquiry/summary';
				} else {
					window.location = this.nextUrl;
				}
            }

			this.is_loading = false;
        },
    }
}
</script>
