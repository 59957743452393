<template>
	<div class="requote_button">
		<button type="button"
		        @click="reqoute()"
		        class="button"
				:class="buttonClass">
			<slot />
		</button>

		<QuoteLoading :visible="is_loading" />
	</div>
</template>

<script>
import QuoteLoading from "./QuoteLoading.vue";

export default {
	name: "RequoteButton",
	components: {
		QuoteLoading
	},
	inject: [
		'is_loading',
		'error',
		'warning',
	],
	props: {
		route: String,
		buttonClass: String,
	},
	methods: {
		async reqoute() {
			this.is_loading = true;
			this.warnings = {};
			this.errors = {};
			this.error = null;

			let result = await this.$http(this.route, 'POST');
			let response = result.response;

			if (!response || response.error) {
				this.error = result.error;
				this.is_loading = false;
				return;
			}


			// Redirect to the results
			// ====================================================================
			if (response.redirect_url) {
				window.location = response.redirect_url;
				return;
			}

			if (response.warning) {
				this.warning = response.warning;
			}

			this.is_loading = false;
		}
	}
}

</script>